<template>
	<div class="container">
		<div class="abstract">
			<img src="../../assets/imgs/smart.png" alt="">
			<div class="text">
				<p class="title">智慧教体</p>
				<p class="subTitle">Wisdom teaching style</p>
			</div>
		</div>
		
		<div class="introduce">
			<div class="text">
				<div class="line"></div>
				<p class="title">智慧体育公园</p>
				<p class="subTitle">Smart Sports Park</p>
				<p class="content">运用“互联网+”的思维和物联网、大数据云计算、移动互联网、信息智能终端等新一代信息技术，对服务、管理、养护过程进行数字化表达、智能化控制和管理，实现智慧化服务、精细化管理，打造智慧公园标杆示范</p>
			</div>
			
			<img src="../../assets/imgs/smartsport1.png" alt="">
		</div>
		
		<div class="architecture">
			<p class="title">架构</p>
			<p class="content">采用先进成熟的互联网技术及物联网技术，通过对公园智慧化建设，并分别面向管理者及游客开发综合管理平台、公园游览APP，集成安防保护系统、环卫管理系统、信息化查询系统、智慧导游系统等功能，使公园成为一个贴近生活，便于管理，安全高效的智慧公园。</p>
		</div>
		
		<div class="swiper">
			<div ref="mySwiper" class="swiper-container" id="traitSwiper"  >
			  <div class="swiper-wrapper">
			    <div class="swiper-slide my-swiper-slide" v-for="item of swiperArr" :key="item">
					<img :src="item" alt="">
				</div>
			  </div>
			 <!--导航器-->
			 
			</div>
			<div class="swiper-button-prev"></div>
			<div class="swiper-button-next"></div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import "swiper/css/swiper.css";
	export default {
		data() {
			return {
				swiperArr: [
					require('@/assets/imgs/park1.png'),
					require('@/assets/imgs/park2.png'),
					require('@/assets/imgs/park3.png'),
					require('@/assets/imgs/park4.png'),
					require('@/assets/imgs/park5.png'),
					require('@/assets/imgs/park6.png'),
					require('@/assets/imgs/park7.png'),
				]
			}
		},
		
		methods: {
			initSwiper(){
			  // eslint-disable-next-line
			  let vueComponent=this//获取vue组件实例
			  //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
			  this.currentSwiper = new Swiper('#traitSwiper', {
				
				autoHeight:'true',//开启自适应高度,容器高度由slide高度决定
				//导航器
				navigation: {
				  nextEl: '.swiper-button-next',
				  prevEl: '.swiper-button-prev',
				},
			   
				slidesPerView: 3, //视口展示slide数1
				spaceBetween: 0
			  })
					
			}
		},
		
		mounted() {
			this.initSwiper()
		}
	}
</script>

<style scoped>
.abstract {
	position: relative;
}

.abstract img {
	width: 100%;
}

.abstract .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	text-align: center;
}

.abstract .text .title {
	font-size: 40px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}

.abstract .text .subTitle {
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
	margin-top: 43px;
}

.introduce {
	display: flex;
	justify-content: space-between;
	padding: 102px 105px 0 120px;
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
}

.line {
	width: 25px;
	height: 3px;
	background: #184BAB;
}

.title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	text-transform: uppercase;
}

.introduce .text .subTitle {
	margin: 7px 0 26px;
}

.introduce .text .content {
	width: 359px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}

.architecture {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 117px 120px 60px;
}

.architecture .content {
	margin-top: 26px;
	width: 531px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	line-height: 20px;
}

.ss-img {
	width: 100%;
	margin-bottom: 44px;
}

.swiper {
	width: 1200px;
	margin: 74px auto 122px;
	position: relative;
}

.swiper-container {
	width: 930px;
	height: 320px;
}

.swiper-button-prev::after {
	display: none;
}


.swiper-button-prev {
	width: 48px;
	height: 48px;
	background-image: url('../../assets/imgs/left.png');
	background-size: cover;
	top: 50%;
	left: 40px;
	transform: translate(0,-50%);
}

.swiper-button-next::after {
	display: none;
}

.swiper-button-next {
	width: 48px;
	height: 48px;
	background-image: url('../../assets/imgs/right.png');
	background-size: cover;
	top: 53%;
	right: 40px;
	transform: translate(0,-50%);
}
</style>